body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.slideInUp {
  animation-name: slideInUp;
  animation-duration: 0.1s;
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  animation-fill-mode: both;
}

@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideInUp {
  0% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  height: 6px;
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background-color: #858181;
  border-radius: 7px;
  box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
}
